
// 横向滚动
export const rowScroll = {
  bind(el) {
    el.animate(
      [
        {
          transform: 'translateX(100%)'
        },
        {
          transform: 'translateX(0)'
        },
        {
          transform: 'translateX(-100%)'
        }
      ],
      {
        duration: 15 * 1000,
        easing: 'ease',
        iterations: Infinity
      }
    )
  }
}