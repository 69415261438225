import Vue from 'vue';
import Vuex from 'vuex';

import chatRoom from './chatRoom';
import gameHall from './gameHall';

Vue.use(Vuex)

const actions = {
    
}
const mutations = {
    LOGIN_STATUS(state, value) {
        state.logined = value
    },
}
const state = {
    user: {},
    logined: true,

}
const getters = {
    user: state => state.user,
}
export default new Vuex.Store({
    
    modules: {
        gameHall,
        chatRoom: chatRoom,
    },
    actions,
    mutations,
    state,
    getters,
})