<template>
  <img :style="{ width, height }" :src="getAssets" />
</template>

<script>
export default {
  props: {
    name: String,
    color: String,
    width: {
      type: String,
      default: '16px',
    },
    height: {
      type: String,
      default: '16px',
    },
  },
  computed:{
    getAssets(){
      return require(`@/assets/icons/${this.name}.svg`)
    }
  }
}
</script>

<style lang="scss" scoped></style>
