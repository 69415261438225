export const headerData = [
  { ch: '游戏大厅', en: 'Game Hall', path: '/gameHall' },
  { ch: '合买中心', en: 'Cooperation', path: '/cooperation' },
  { ch: '定制跟单', en: 'Customizations', path: '/customizations' },
  { ch: '用户中心', en: 'Account', path: '/account' },
  { ch: 'APP下载', en: 'DownLoad', path: '/downLoad' },
  { ch: '聊天室', en: 'Chat Room', path: '/chatRoom' },
]

export const footerData = [
  { ch: '存款帮助', path: '', en: '' },
  { ch: '联系我们', path: '', en: '' },
  { ch: '取款帮助', path: '', en: '' },
  { ch: '常见问题', path: '', en: '' }
]

export const gameOptionData = [
  {
    id: 1,
    icon: 'hour',
    ch: '时时彩',
    en: '',
    flag: false,
    child: [
      { ch: '河内5分彩', en: '', path: '/Hanoi' },
      { ch: '腾讯分分彩', en: '', path: '/Tencent' },
      { ch: '印尼5分彩', en: '', path: '/Indonesia' }
    ]
  },
  {
    id: 2,
    icon: 'number',
    ch: '11选5',
    en: '',
    flag: false,
    child: [
      { ch: '广东11选5', en: '', path: '/Guangdong' },
      { ch: '山东11选5', en: '', path: '/Shandong' },
      { ch: '泰国11选5', en: '', path: '/Taiguo' },
      { ch: '加拿大11选5', en: '', path: '/Jianada' }
    ]
  },
  {
    id: 5,
    icon: 'three3d',
    ch: '低频彩',
    en: '',
    flag: false,
    child: [
      { ch: '福彩3D', en: '', path: '/Fc3d' },
      { ch: '排列三', en: '', path: '/Paisan' }
    ]
  }
]
export const userCoreData = [
  { ch: '我的账户', en: '', path: '/Account' },
  { ch: '余额宝', en: '', path: '/Assets' },
  { ch: '游戏记录', en: '', path: '/GameRecord' },
  { ch: '充值记录', en: '', path: '/Rechange' },
  { ch: '提现记录', en: '', path: '/CashRecord' },
  // { ch: '赠送记录', en: '', path: '/SendRecord' },
  { ch: '红包记录', en: '', path: '/RedRecord' },
  { ch: '幸运抽奖', en: '', path: '/Prize' },
  { ch: '资金明细', en: '', path: '/MoneyRecord' },
  { ch: '站内信', en: '', path: '/Mail' },
  { ch: '公告', en: '', path: '/Notice' },
  { ch: '会员管理', en: '', path: '/Member' }
]
