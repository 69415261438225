<template>
  <footer>
      <div class="nav-item"  v-for="(item, index) in footer" :key="index">
        <a href="">{{item.ch}}</a>
      </div>
  </footer>
</template>

<script>
import { footerData } from '@/assets/data'
export default {
  props: {
    current: {
      type: [Number, String],
      default: 0,
    },
  },
  data(){
    return{
      footer:footerData
    }
  }
}
</script>

<style lang="scss" scoped>
footer {
  min-width: 1200px;
  margin: 0 auto;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--black-color);
  .nav-item {
    font-size: 18px;
    transition: color 0.3s linear;
    border-left: 1px solid #fff;
    padding: 0 20px;
    a{
      color: var(--white-color);
    }
  }
  .nav-item:first-child{
    border-left: none;

  }
  .nav-active {
    color: var(--red-color);
  }
}
</style>
