//时时彩
import ssc from './01_ssc';
import yiyiwu from './02_115';
import fucai from './03_fc';


const actions = {
    show(context, value) {
        // console.log(context)
        // console.log(value)
        // console.log(ssc)
    },
    //导航切换
    tabChange(context, object) {
        object.checkList.forEach(item => {
            item.typeList.forEach(playType => {
                context.dispatch('playTypeChange', playType)
            });
        });
        context.commit('TAB_CHANGE', object)
    },
    // 侧边栏导航切换
    gameChange(context, object) {
        context.commit('GAME_CHANGE', object)
    },
    //游戏初始
    init(context, value) {
        const init = {}
        init.tabs = context.state.gameData.tabs
        init.activeTab = init.tabs[0]
        init.playType = init.activeTab.checkList[0].typeList[0]
        init.playTypeName = init.activeTab.checkList[0].name
        context.commit('GAME_INIT', init)
    },

    //选择游戏类型
    playTypeChange(context, object) {
        if (object !== context.state.playType) {
            if (object.positions) {
                object.positions.forEach(position => {
                    context.commit('BALL_CLOSE', position.ballList)
                    if (position.operations) {
                        context.commit('BUTTON_CANCLE', { position })
                    }
                });
            } else {
                // console.log(object.tips)
            }
            context.commit('PLAY_CHANGE', object)
        }
    },
    //点击小球
    ballTap(context, object) {
        if (object.position.title.indexOf('胆码') > -1 && !object.ball.active) {
            const position = context.state.playType.positions.find(item => item.title.indexOf('拖码') > -1)
            const ball = position.ballList.find(item => item.ball === object.ball.ball)
            context.commit('BALL_CLOSE', [ball])
            context.commit('BUTTON_CANCLE', { position, ball: object.ball })
            const list = object.position.ballList.filter(ball => ball.active)
            if (list.length >= 2 && context.state.playType.label.indexOf('组六') > -1) context.commit('BALL_CLOSE', [list[1]])
            if (list.length >= 1 && context.state.playType.label.indexOf('组三') > -1) context.commit('BALL_CLOSE', [list[0]])
            context.commit('BALL_OPEN', [object.ball])



        } else if (object.position.title.indexOf('拖码') > -1 && !object.ball.active) {
            const position = context.state.playType.positions.find(item => item.title.indexOf('胆码') > -1)
            const ball = position.ballList.find(item => item.ball === object.ball.ball)
            context.commit('BALL_CLOSE', [ball])
            context.commit('BALL_OPEN', [object.ball])
        } else {
            if (object.ball.active) {
                context.commit('BALL_CLOSE', [object.ball])
            } else {
                context.commit('BALL_OPEN', [object.ball])
            }
            if (object.position.operations) {
                context.commit('BUTTON_CANCLE', object)
            }
        }
    },
    // 点击 小球右侧的按钮
    ballChange(context, object) {
        context.commit('BALL_CHANGE', object)
        let ballList = []
        switch (object.btn.label) {
            case '全':
                if (object.position.title.indexOf('拖码') > -1) {
                    const position = context.state.playType.positions.find(item => item.title.indexOf('胆码') > -1)
                    context.commit('BALL_CLOSE', position.ballList)
                }
                context.commit('BALL_OPEN', object.position.ballList)
                break;
            case '大':
                ballList = object.position.ballList.filter((ball, index) => index >= Math.floor(object.position.ballList.length / 2))
                context.commit('BALL_CLOSE', object.position.ballList)
                context.commit('BALL_OPEN', ballList)
                break;
            case '小':
                ballList = object.position.ballList.filter((ball, index) => index < Math.floor(object.position.ballList.length / 2))
                context.commit('BALL_CLOSE', object.position.ballList)
                context.commit('BALL_OPEN', ballList)
                break;
            case '奇':
                ballList = object.position.ballList.filter((ball, index) => Number(ball.ball) % 2 === 1)
                context.commit('BALL_CLOSE', object.position.ballList)
                context.commit('BALL_OPEN', ballList)
                break;
            case '偶':
                ballList = object.position.ballList.filter((ball, index) => Number(ball.ball) % 2 === 0)
                context.commit('BALL_CLOSE', object.position.ballList)
                context.commit('BALL_OPEN', ballList)
                break;
            case '清':
                context.commit('BALL_CLOSE', object.position.ballList)
                break;
            default:
                break;
        }
    },
    // 重置游戏
    reset(context, value) {
        if (context.state.lotteryValue = '') {
            context.state.lotteryValue = ''
        } else {
            context.state.playType.positions.forEach(position => {
                context.commit('BALL_CLOSE', position.ballList)
                context.commit('BUTTON_CANCLE', { position })
            })
        }

    },
    // // 时时彩  判断 导航项
    // sscTabsBranch(context, object) {
    //     switch (context.state.activeTab.name) {
    //         
    //     }
    // },


}
const mutations = {
    //初始数据
    GAME_INIT(state, object) {
        for (const key in object) {
            state[key] = object[key]
        }
    },
    //游戏分类的切换
    CAGEGORY_CHANGE(state, array) {
        state.activeCategorys = array
        state.lotteryValue = ''
    },
    //游戏切换
    GAME_CHANGE(state, object) {
        state.activeGame = object.type
        state.gameCn = object.name
        if (object.communalId == 1) {
            state.gameData = ssc
        }
        if (object.communalId == 2) {
            state.gameData = yiyiwu
        }
        if (object.communalId == 3) {
            state.gameData = fucai
        }
        state.tabs = state.gameData.tabs
        state.activeTab = state.tabs[0]
        state.playType = state.activeTab.checkList[0].typeList[0]
        state.playTypeName = state.activeTab.checkList[0].name
        state.lotteryValue = ''
    },
    //游戏导航切换 
    TAB_CHANGE(state, object) {
        state.activeTab = object
        state.playType = object.checkList[0].typeList[0]
        state.lotteryValue = ''
    },
    //游戏玩法切换
    PLAY_CHANGE(state, object) {
        state.playType = object
        state.lotteryValue = ''
    },
    // 下期期号的改变
    SET_NEXTEXPECT(state, num) {
        state.nowExpect = num
    },
    //小球 点亮
    BALL_OPEN(state, array) {
        array.forEach(ball => {
            ball.active = true
        });
    },
    //小球 取消点亮
    BALL_CLOSE(state, array) {
        array.forEach(ball => {
            ball.active = false
        });
    },
    //小球右侧 按钮选中
    BALL_CHANGE(state, object) {
        object.position.operations.forEach(btn => {
            btn.active = false
        });
        object.btn.active = true
    },
    //小球右侧按钮 取消选中
    BUTTON_CANCLE(state, object) {
        if (object.position.operations) {
            object.position.operations.forEach(btn => {
                btn.active = false
            });
        }

    },

    //手动输入的下注
    LOTTORY_INPUT(state, value) {
        state.lotteryValue = value
    }

}
const state = {
    //当前展开的游戏分类
    activeCategorys: ['01'],
    //当前选择的游戏
    activeGame: 'heineione',
    gameCn: '河内5分彩',
    //当前 游戏 数据
    gameData: ssc,
    //游戏玩法(导航)数据
    tabs: {},
    //当前选择的游戏 导航
    activeTab: {},
    //选中的玩法 
    playType: {},
    // 玩法名称
    playTypeName: '',
    //手动输入的下注数据
    lotteryValue: '',
    // 当前下注期号
    nowExpect: '',
}
const getters = {

}

export default {
    namespaced: true,
    actions,
    mutations,
    state,
    getters,
}