import request from '@/api/request';


//  登录  请求登录
export function login(data) {
    return request({
        method: 'post',
        url: '/Login/Login',
        data,
    })
}

//  获取验证码
export function checkVerify(data) {
    return request({
        method: 'post',
        url: '/Login/checkVerify',
        data,
        responseType: 'blob',
    })
}

//  获取config
export function getConfig(data) {
    return request({
        method: 'post',
        url: 'Reg/smlist',
        data,
    })
}