// 115  //数据
export default {
    tabs : [
        {
            id: '01',
            name: '前三',
            checkList: [
                {
                    name: '前三直选',
                    typeList: [
                        {
                            value: '00',
                            name: '前三直选',
                            label: '直选复式',
                            example: '从第一丶第二丶第三位各选一个号码组成一注。',
                            playType: 0,
                            positions: [
                                {
                                    order: '1',
                                    title: '第一位',
                                    ballList: [
                                        {ball: '01', active: false},
                                        {ball: '02', active: false},
                                        {ball: '03', active: false},
                                        {ball: '04', active: false},
                                        {ball: '05', active: false},
                                        {ball: '06', active: false},
                                        {ball: '07', active: false},
                                        {ball: '08', active: false},
                                        {ball: '09', active: false},
                                        {ball: '10', active: false},
                                        {ball: '11', active: false},
                                    ],
                                    operations: [
                                        {value: '01', label: '全', active: false},
                                        {value: '02', label: '大', active: false},
                                        {value: '03', label: '小', active: false},
                                        {value: '04', label: '奇', active: false},
                                        {value: '05', label: '偶', active: false},
                                        {value: '06', label: '清', active: false},
                                    ]
                                },
                                {
                                    order: '2',
                                    title: '第二位',
                                    ballList: [
                                        {ball: '01', active: false},
                                        {ball: '02', active: false},
                                        {ball: '03', active: false},
                                        {ball: '04', active: false},
                                        {ball: '05', active: false},
                                        {ball: '06', active: false},
                                        {ball: '07', active: false},
                                        {ball: '08', active: false},
                                        {ball: '09', active: false},
                                        {ball: '10', active: false},
                                        {ball: '11', active: false},
                                    ],
                                    operations: [
                                        {value: '01', label: '全', active: false},
                                        {value: '02', label: '大', active: false},
                                        {value: '03', label: '小', active: false},
                                        {value: '04', label: '奇', active: false},
                                        {value: '05', label: '偶', active: false},
                                        {value: '06', label: '清', active: false},
                                    ]
                                },
                                {
                                    order: '3',
                                    title: '第三位',
                                    ballList: [
                                        {ball: '01', active: false},
                                        {ball: '02', active: false},
                                        {ball: '03', active: false},
                                        {ball: '04', active: false},
                                        {ball: '05', active: false},
                                        {ball: '06', active: false},
                                        {ball: '07', active: false},
                                        {ball: '08', active: false},
                                        {ball: '09', active: false},
                                        {ball: '10', active: false},
                                        {ball: '11', active: false},
                                    ],
                                    operations: [
                                        {value: '01', label: '全', active: false},
                                        {value: '02', label: '大', active: false},
                                        {value: '03', label: '小', active: false},
                                        {value: '04', label: '奇', active: false},
                                        {value: '05', label: '偶', active: false},
                                        {value: '06', label: '清', active: false},
                                    ]
                                },
                            ]
                        },
                    ]
                },
                {
                    name: '前三组选',
                    typeList: [
                        {
                            value: '01',
                            name: '前三组选',
                            label: '组选复式',
                            example: '从01-11中任意选择3个或3个以上号码。',
                            playType: 0,
                            positions: [
                                {
                                    order: '1',
                                    title: '组选',
                                    ballList: [
                                        {ball: '01', active: false},
                                        {ball: '02', active: false},
                                        {ball: '03', active: false},
                                        {ball: '04', active: false},
                                        {ball: '05', active: false},
                                        {ball: '06', active: false},
                                        {ball: '07', active: false},
                                        {ball: '08', active: false},
                                        {ball: '09', active: false},
                                        {ball: '10', active: false},
                                        {ball: '11', active: false},
                                    ],
                                    operations: [
                                        {value: '01', label: '全', active: false},
                                        {value: '02', label: '大', active: false},
                                        {value: '03', label: '小', active: false},
                                        {value: '04', label: '奇', active: false},
                                        {value: '05', label: '偶', active: false},
                                        {value: '06', label: '清', active: false},
                                    ]
                                },
                                
                            ]
                        },
                    ]
                },
            ]
        },
        {
            id: '02',
            name: '前二',
            checkList: [
                {
                    name: '前二直选',
                    typeList: [
                        {
                            value: '00',
                            name: '前二直选',
                            label: '直选复式',
                            example: '从第一丶第二位各选一个号码组成一注。',
                            playType: 0,
                            positions: [
                                {
                                    order: '1',
                                    title: '第一位',
                                    ballList: [
                                        {ball: '01', active: false},
                                        {ball: '02', active: false},
                                        {ball: '03', active: false},
                                        {ball: '04', active: false},
                                        {ball: '05', active: false},
                                        {ball: '06', active: false},
                                        {ball: '07', active: false},
                                        {ball: '08', active: false},
                                        {ball: '09', active: false},
                                        {ball: '10', active: false},
                                        {ball: '11', active: false},
                                    ],
                                    operations: [
                                        {value: '01', label: '全', active: false},
                                        {value: '02', label: '大', active: false},
                                        {value: '03', label: '小', active: false},
                                        {value: '04', label: '奇', active: false},
                                        {value: '05', label: '偶', active: false},
                                        {value: '06', label: '清', active: false},
                                    ]
                                },
                                {
                                    order: '2',
                                    title: '第二位',
                                    ballList: [
                                        {ball: '01', active: false},
                                        {ball: '02', active: false},
                                        {ball: '03', active: false},
                                        {ball: '04', active: false},
                                        {ball: '05', active: false},
                                        {ball: '06', active: false},
                                        {ball: '07', active: false},
                                        {ball: '08', active: false},
                                        {ball: '09', active: false},
                                        {ball: '10', active: false},
                                        {ball: '11', active: false},
                                    ],
                                    operations: [
                                        {value: '01', label: '全', active: false},
                                        {value: '02', label: '大', active: false},
                                        {value: '03', label: '小', active: false},
                                        {value: '04', label: '奇', active: false},
                                        {value: '05', label: '偶', active: false},
                                        {value: '06', label: '清', active: false},
                                    ]
                                },
                            ]
                        },
                    ]
                },
                {
                    name: '前二组选',
                    typeList: [
                        {
                            value: '01',
                            name: '前二组选',
                            label: '组选复式',
                            example: '从01-11中任意选择2个或2个以上号码。',
                            playType: 0,
                            positions: [
                                {
                                    order: '1',
                                    title: '组选',
                                    ballList: [
                                        {ball: '01', active: false},
                                        {ball: '02', active: false},
                                        {ball: '03', active: false},
                                        {ball: '04', active: false},
                                        {ball: '05', active: false},
                                        {ball: '06', active: false},
                                        {ball: '07', active: false},
                                        {ball: '08', active: false},
                                        {ball: '09', active: false},
                                        {ball: '10', active: false},
                                        {ball: '11', active: false},
                                    ],
                                    operations: [
                                        {value: '01', label: '全', active: false},
                                        {value: '02', label: '大', active: false},
                                        {value: '03', label: '小', active: false},
                                        {value: '04', label: '奇', active: false},
                                        {value: '05', label: '偶', active: false},
                                        {value: '06', label: '清', active: false},
                                    ]
                                },
                                
                            ]
                        },
                    ]
                },
            ]
        },
    ]
}

