import VueRouter from 'vue-router'
import Login from '@/pages/Login';
import accountChildren from './account';
const Home = () => import('@/pages/ChatRoom/index');
const Conversations = () => import('@/pages/ChatRoom/Conversations');
const Contacts = () => import('@/pages/ChatRoom/contacts');
const PrivateChat = () => import('@/pages/ChatRoom/PrivateChat');
const GroupChat = () => import('@/pages/ChatRoom/GroupChat');

const router = new VueRouter({
  // mode:'history', //history模式
  routes: [
    {
      path: '/',
      redirect: '/login',
    },
    {
      name: 'Login',
      path: '/login',
      component: Login,
      meta: { title: '登录', },
    },
    {
      name: 'Game Hall',
      path: '/gameHall',
      component: () => import('@/pages/GameHall'),
      meta: {
        title: '游戏大厅'
      },
    },
    {
      name: 'Cooperation',
      path: '/cooperation',
      component: () => import('@/pages/Cooperation/index'),

      meta: {
        title: '合买中心',
      },
    },
    {
      name: 'CooperationDetail',
      path: '/cooperationDetail',
      component: () => import('@/pages/Cooperation/detail'),

      meta: {
        title: '合买详情',
      },

    },
    {
      name: 'Customizations',
      path: '/customizations',
      component: () => import('@/pages/Customizations'),
      meta: {
        title: '定制跟单'
      },
    },
    {
      // name: 'Account',
      path: '/account',
      component: () => import('@/pages/Account'),
      children: accountChildren,
      meta: {
        title: '用户中心'
      },
    },
    {
      name: 'DownLoad',
      path: '/downLoad',
      component: () => import('@/pages/DownLoad'),
      meta: {
        title: 'APP下载'
      },
    },
    {
      name: 'Chat Room',
      path: '/chatRoom',
      component: Home,
      redirect:'/conversations',
      // redirect: '/conversations',
      children: [
        {
          path: '/conversations',
          component: Conversations,
          children: [
            {
              path: '/privatechat/:id',
              component: PrivateChat,
            },
            {
              path: '/groupchat/:id',
              component: GroupChat,
            },
          ],
        },
        {
          path: '/contacts',
          component: Contacts,
        },
      ],
      meta: {
        title: '聊天室'
      },
    },
    // {
    //   name: 'Usdt Course',
    //   path: '/usdtCourse',
    //   component: () => import('@/pages/UsdtCourse'),
    //   meta: {
    //     title: 'U充教程'
    //   },
    // },
    {
      path: '*',
      redirect: '/gameHall',
    },
  ]
})
router.beforeEach((to, from, next) => {
  // let session_key = localStorage.getItem('session_key')
  // if(session_key) {
  //   next()
  // } else {
  //   router.history.push('/login')
  // }  
  next()
})
router.afterEach((to, from) => {

})
export default router
