<template>
  <div class="login">
    <div class="box">
      <div class="form">
        <p class="title form-item">用户登录</p>
        <p class="title-english form-item">MEMBER LOGIN</p>
        <div class="input form-item">
          <span class="label">用户名</span>
          <input type="text" v-model.trim="form.username" />
          <!-- <button class="end"></button> -->
        </div>
        <div class="input form-item">
          <span class="label">密码</span>
          <input type="password" v-model.trim="form.password" />
          <!-- <button class="end"></button> -->
        </div>
        <div class="input form-item">
          <span class="label">验证码</span>
          <input
            type="number"
            v-model.trim="form.authCode"
            @keyup.enter="login"
          />
          <img style="cursor: pointer" :src="authCode" @click="toAuthCode" />
          <!-- <button class="end" @click="refresh">{{ authCode }}</button> -->
        </div>
        <button class="btn-login form-item" @click="login" :disabled="disabled">
          登 录
        </button>
      </div>
      <div class="download">
        <div class="logo">logo</div>
        <p class="address">https://{{hostname}}</p>
        <div class="address-code">
          <div class="block">
            <div class="box">
              <img
                class="image-code"
                :src="configData.image1"
                alt=""
              />
            </div>
            <p class="label">安卓版下载</p>
          </div>
          <div class="block">
            <div class="box">
              <img
                class="image-code"
                :src="configData.image2"
                alt=""
              />
            </div>
            <p class="label">手机官网</p>
          </div>
        </div>
        <p class="tips">
          温馨提示：使用手机浏览器扫描上方二维码（不要使用QQ或微信）
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import { mapMutations, mapState } from "vuex";
import { login, checkVerify, getConfig } from "@/api/login";
import { getUserInfo } from "@/api/account/account";

export default {
  computed: {
    ...mapState(["logined"]),
    disabled() {
      return !(
        this.form.username &&
        this.form.password &&
        this.form.authCode.toString().length === 4
      );
    },
  },
  data() {
    return {
      form: {
        username: "",
        password: "",
        authCode: "",
      },
      hostname:'',
      configData:null,
      authCode: null,
    };
  },
  created() {
    const session_key = localStorage.getItem("session_key");
    if (this.logined && session_key) {
      this.$router.replace("/gameHall");
    } else {
      this.LOGIN_STATUS(false);
    }
    this.toAuthCode();
    this.getConfigData();
    this.hostname = window.location.hostname;
  },
  methods: {
    ...mapMutations(["LOGIN_STATUS"]),
    login() {
      if (!this.disabled) {
        let params = {
          phone: this.form.username,
          pwd: this.form.password,
          verify: this.form.authCode,
        };
        login(params).then((res) => {
          if (res.code === 1) {
            localStorage.setItem("session_key", res.session_key);
            this.getUserInfos(res.session_key);
            this.$message.success(res.msg);
            this.LOGIN_STATUS(true);
            this.$router.replace("/gameHall");
          } else {
            this.$message.error(res.msg);
          }
        });
      }
    },
    getConfigData() {
      getConfig().then((res) => {
        this.configData = res
      });
    },
    getUserInfos(v) {
      getUserInfo({ session_key: v }).then((res) => {
        localStorage.setItem("userInfo", JSON.stringify(res.user));
      });
    },
    toAuthCode() {
      checkVerify().then((res) => {
        this.authCode = URL.createObjectURL(res);
      });

      // return value.toString().slice(-4)
    },
  },
};
</script>
<style lang="scss" scoped>
.login {
  width: 100%;
  // height: 100%;
  min-height: 100%;
  position: absolute;
  padding: 20px;
  z-index: 99;
  background: url(@/assets/imgs/background_login.png);
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .box {
    width: 1520px;
    // height: 780px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    // .form:nth-child(n) {
    //     width: 57.3%;
    // }
    .form {
      max-width: 700px;
      // max-width: 46%;
      min-width: 580px;
      height: 780px;
      flex-grow: 1;
      margin-bottom: 10px;
      background-color: #fff;
      border-radius: 8px;
      box-shadow: 0 4px 12px 2px #999;
      // padding: 0 150px;
      display: flex;
      flex-direction: column;
      align-items: center;
      z-index: 1;
      .form-item {
        width: 57.3%;
      }
      .title {
        margin-top: 130px;
        font-size: 40px;
        font-weight: bold;
      }
      .title-english {
        margin-top: 29px;
        font-size: 18px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #333333;
        // margin-bottom: 30px;
      }
      .input {
        // width: 100%;
        margin-top: 54px;
        border-bottom: 1px solid #9b9b9b;
        font-size: 17px;
        color: #999;
        display: flex;
        align-items: center;
        position: relative;

        .label {
          width: 75px;
          padding-left: 2px;
          position: absolute;
          z-index: 1;
        }
        input {
          flex-grow: 1;
          border: none;
          outline: none;
          line-height: 50px;
          background: none;
          padding-left: 75px;
          z-index: 10;
        }
        .end {
          width: 70px;
          height: 30px;
          background: linear-gradient(to right, #ba7b40, #d0a54a, #ba7b40);
          font-size: 22px;
          font-weight: bold;
          color: #fff;
          position: absolute;
          right: 0;
          z-index: 100;
        }
      }
      .btn-login {
        // width: 100%;
        height: 60px;
        margin-top: 60px;
        background: #d72221;
        border-radius: 30px;
        color: #ffffff;
        font-size: 17px;
        font-weight: bold;
      }
      .btn-login[disabled] {
        background: #e65151;
      }
      .btn-login:focus:not([disabled]) {
        background: #e72f2f;
      }
      .btn-login:hover:not([disabled]) {
        background: #dd0d0d;
      }
    }
    .download {
      // max-width: 818px;
      max-width: 54%;
      min-width: 700px;
      flex-grow: 1;
      // width: 818px;
      height: 780px;
      // height: 100%;
      padding-top: 76px;
      background: linear-gradient(to bottom, #d61f1f, #ff672b);
      border-radius: 8px;
      display: flex;
      // justify-content: center;
      flex-direction: column;
      align-items: center;
      font-size: 22px;
      box-shadow: 0 4px 12px 2px #000;

      color: #fff;
      .logo {
        width: 152px;
        height: 152px;
        background-color: #fff;
        font-size: 49px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 20px;
        color: #000;
      }
      .address {
        font-size: 36px;
        margin: 50px 0;
      }
      .address-code {
        width: 100%;
        display: flex;
        padding: 0 50px;
        justify-content: space-around;
        .block {
          display: flex;
          flex-direction: column;
          align-items: center;
          .box {
            width: 242px;
            height: 242px;
            background: #ffffff;
            border-radius: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            .image-code {
              width: 218px;
              height: 218px;
            }
          }
          .label {
            margin-top: 25px;
          }
        }
      }
      .tips {
        margin-top: 60px;
      }
    }
  }
}
</style>
