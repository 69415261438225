const actions = {
    
}
const mutations = {
    // 侧边栏切换
    SIDEBAR_CHANGE(state, value) {  
        state.sidebarInit = value
    },
    // 通讯录 选项切换
    OPTION_CHANGE(state, value) {
        state.currentOption = value
    }

}
const state = {
    sidebarInit: false, //侧边栏初始  默认 对话


    currentTalkId: '', //当前聊天人



    currentOption: '01', //通讯录选项


}
const getters = {
    
}

export default {
    namespaced: true,
    actions,
    mutations,
    state,
    getters,
}