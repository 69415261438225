<template>
  <header>
    <nav>
      <router-link
        class="nav-item"
        active-class="nav-active"
        v-for="(item, index) in header"
        :key="index"
        :to="item.path"
      >
        <div class="ch">{{ item.ch }}</div>
        <div class="en">{{ item.en }}</div>
      </router-link>
    </nav>
  </header>
</template>

<script>
import { headerData } from "@/assets/data";
export default {
  computed: {
    header() {
      return headerData;
    }
  },
};
</script>

<style lang="scss" scoped>
header {
  width: 100%;
  min-width: 1200px;
  height: 94px;
  display: flex;
  justify-content: center;
  background-color: var(--black-color);

  nav {
    width: 1200px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .nav-item {
      color: var(--white-color);
      font-size: 18px;
      text-align: center;
      transition: color 0.3s linear;
      cursor: pointer;
    }
    .nav-active {
      color: var(--bule-color);
    }
  }
}
</style>
