export default [
    {
        path: '/',
        name: 'account',
        redirect: 'accounts',
    },
    {
        path: 'accounts',
        name: 'accounts',
        component: () => import('@/pages/Account/views/Accounts'),
        meta: {name: '我的账户',}
    },
    {
        path: 'balance',
        // name: 'balance',
        component: () => import('@/pages/Account/views/Balance'),
        meta: {name: '提现转消费',},
        children: [
            {
                path: '/',
                name: 'balance',
                redirect: 'balances',
            },
            {
                path: 'balances',
                name: 'balances',
                component: () => import('@/pages/Account/views/Balance/views/Balances'),
                meta: {name: '提现转消费'}
            },
            {
                path: 'detail',
                name: 'detail',
                component: () => import('@/pages/Account/views/Balance/views/Detail'),
                meta: {name: '明细记录'}
            },
        ]
    },
    {
        path: 'gameList',
        // name: 'gameList',
        component: () => import('@/pages/Account/views/GameList'),
        meta: {name: '游戏记录',},
        children: [
            {
                path: '/',
                name: 'gameList',
                redirect: 'zigou',
            },
            {
                path: 'zigou',
                name: 'zigou',
                component: () => import('@/pages/Account/views/GameList/views/Zigou'),
                meta: {name: '自购记录'}
            },
            {
                path: 'hemai',
                name: 'hemai',
                component: () => import('@/pages/Account/views/GameList/views/Hemai'),
                meta: {name: '合买记录'}
            },
            {
                path: 'zhuihao',
                name: 'zhuihao',
                component: () => import('@/pages/Account/views/GameList/views/Zhuihao'),
                meta: {name: '追号记录'}
            },
            {
                path: 'dingzhi',
                name: 'dingzhi',
                component: () => import('@/pages/Account/views/GameList/views/Dingzhi'),
                meta: {name: '定制记录'}
            },
        ]
    },
    {
        path: 'recharge',
        name: 'recharge',
        component: () => import('@/pages/Account/views/Recharge'),
        meta: {name: '充值记录',}
    },
    {
        path: 'withdraw',
        name: 'withdraw',
        component: () => import('@/pages/Account/views/Withdraw'),
        meta: {name: '提现记录',}
    },
    // {
    //     path: 'gift',
    //     name: 'gift',
    //     component: () => import('@/pages/Account/views/Gift'),
    //     meta: {name: '赠送记录',}
    // },
    {
        path: 'redPacket',
        // name: 'redPacket',
        component: () => import('@/pages/Account/views/RedPacket'),
        meta: {name: '红包记录',},
        children: [
            {
                path: '/',
                name: 'redPacket',
                redirect: 'give',
            },
            {
                path: 'give',
                name: 'give',
                component: () => import('@/pages/Account/views/RedPacket/views/Give'),
                meta: {name: '红包派发记录'}
            },
            {
                path: 'get',
                name: 'get',
                component: () => import('@/pages/Account/views/RedPacket/views/Get'),
                meta: {name: '红包领取记录'}
            },
        ]
    },
    // {
    //     path: 'luckyPrize',
    //     // name: 'luckyPrize',
    //     component: () => import('@/pages/Account/views/LuckyPrize'),
    //     meta: {name: '幸运抽奖',},
    //     children: [
    //         {
    //             path: '/',
    //             name: 'luckyPrize',
    //             redirect: 'turntable',
    //         },
    //         {
    //             path: 'turntable',
    //             name: 'turntable',
    //             component: () => import('@/pages/Account/views/LuckyPrize/views/Turntable'),
    //             meta: {name: '幸运转盘'}
    //         },
    //         {
    //             path: 'win',
    //             name: 'win',
    //             component: () => import('@/pages/Account/views/LuckyPrize/views/Win'),
    //             meta: {name: '中奖记录'}
    //         },
    //     ]
    // },
    {
        path: 'moneyDetail',
        name: 'moneyDetail',
        component: () => import('@/pages/Account/views/MoneyDetail'),
        meta: {name: '资金明细',}
    },
    {
        path: 'message',
        name: 'message',
        component: () => import('@/pages/Account/views/Message'),
        meta: {name: '站内信',}
    },
    {
        path: 'notice',
        name: 'notice',
        component: () => import('@/pages/Account/views/Notice'),
        meta: {name: '公告',}
    },
    {
        path: 'vip',
        name: 'vip',
        component: () => import('@/pages/Account/views/Vip'),
        meta: {name: '会员管理',}
    },
]
