import request from '@/api/request';


//  用户中心  获取用户信息
export function getUserInfo(data) {
    return request({
        method: 'post',
        url: '/User/index',
        data,
    })
}
//  用户中心  获取银行卡信息
export function yinhangkaList(data) {
    return request({
        method: 'post',
        url: '/Banklist/address_list',
        data,
    })
}
//  用户中心  转消费
export function exchangektxdxf(data) {
    return request({
        method: 'post',
        url: '/Transfer/exchangektxdxf',
        data,
    })
}

//  用户中心  自购记录
export function zigouList(data) {
    return request({
        method: 'post',
        url: '/Goodsorder/orderlist',
        data,
    })
}
//  用户中心  自购记录
export function zigouOrderdetail(data) {
    return request({
        method: 'post',
        url: '/Goodsorder/orderdetail',
        data,
    })
}
//  用户中心  合购记录
export function hegouiList(data) {
    return request({
        method: 'post',
        url: '/Goods/myhemailist',
        data,
    })
}

//  用户中心  定制记录
export function dingzhiList(data) {
    return request({
        method: 'post',
        url: '/Goods/mydingzhilistnew',
        data,
    })
}
//  用户中心  取消定制
export function dingzhiquxiao(data) {
    return request({
        method: 'post',
        url: '/Capital/dingzhiquxiao',
        data,
    })
}
//  用户中心  充值记录
export function chongzhiList(data) {
    return request({
        method: 'post',
        url: '/Recharge/rechargelist',
        data,
    })
}
//  用户中心  提现记录
export function withdraw(data) {
    return request({
        method: 'post',
        url: '/Recharge/withdraw',
        data,
    })
}
//  用户中心  发放记录
export function sentList(data) {
    return request({
        method: 'post',
        url: '/Capital/fsredamount',
        data,
    })
}
//  用户中心  领取记录
export function receiveList(data) {
    return request({
        method: 'post',
        url: '/Transfer/getUserRedPacketList',
        data,
    })
}
//  用户中心  资金明细
export function zijinIndex(data) {
    return request({
        method: 'post',
        url: '/Capital/index',
        data,
    })
}
//  用户中心  站内信
export function zhanneiList(data) {
    return request({
        method: 'post',
        url: '/Feedback/massagelist',
        data,
    })
}
//  用户中心  公告
export function noticeList(data) {
    return request({
        method: 'post',
        url: '/Notice/index',
        data,
    })
}
//  用户中心  团队列表
export function teamList(data) {
    return request({
        method: 'post',
        url: '/Spread/index',
        data,
    })
}
// 获取所有用户
export function alluseslist(data) {
    return request({
        method: 'post',
        url: '/User/alluselist',
        data,
    })
}