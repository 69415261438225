// 时时彩  //数据
export default {
    tabs : [

        {
            id: '03',
            name: '三星',
            checkList: [
                {
                    name: '三星直选',
                    typeList: [
                        {
                            value: '00',
                            name: '三星直选',
                            label: '直选复式',
                            example: '从百丶十丶个位各选一个号码组成一注。',
                            playType: 0,
                            positions: [
                                {
                                    order: '1',
                                    title: '百位',
                                    ballList: [
                                        {ball: '0', active: false},
                                        {ball: '1', active: false},
                                        {ball: '2', active: false},
                                        {ball: '3', active: false},
                                        {ball: '4', active: false},
                                        {ball: '5', active: false},
                                        {ball: '6', active: false},
                                        {ball: '7', active: false},
                                        {ball: '8', active: false},
                                        {ball: '9', active: false},
                                    ],
                                    operations: [
                                        {value: '01', label: '全', active: false},
                                        {value: '02', label: '大', active: false},
                                        {value: '03', label: '小', active: false},
                                        {value: '04', label: '奇', active: false},
                                        {value: '05', label: '偶', active: false},
                                        {value: '06', label: '清', active: false},
                                    ]
                                },
                                {
                                    order: '2',
                                    title: '十位',
                                    ballList: [
                                        {ball: '0', active: false},
                                        {ball: '1', active: false},
                                        {ball: '2', active: false},
                                        {ball: '3', active: false},
                                        {ball: '4', active: false},
                                        {ball: '5', active: false},
                                        {ball: '6', active: false},
                                        {ball: '7', active: false},
                                        {ball: '8', active: false},
                                        {ball: '9', active: false},
                                    ],
                                    operations: [
                                        {value: '01', label: '全', active: false},
                                        {value: '02', label: '大', active: false},
                                        {value: '03', label: '小', active: false},
                                        {value: '04', label: '奇', active: false},
                                        {value: '05', label: '偶', active: false},
                                        {value: '06', label: '清', active: false},
                                    ]
                                },
                                {
                                    order: '3',
                                    title: '个位',
                                    ballList: [
                                        {ball: '0', active: false},
                                        {ball: '1', active: false},
                                        {ball: '2', active: false},
                                        {ball: '3', active: false},
                                        {ball: '4', active: false},
                                        {ball: '5', active: false},
                                        {ball: '6', active: false},
                                        {ball: '7', active: false},
                                        {ball: '8', active: false},
                                        {ball: '9', active: false},
                                    ],
                                    operations: [
                                        {value: '01', label: '全', active: false},
                                        {value: '02', label: '大', active: false},
                                        {value: '03', label: '小', active: false},
                                        {value: '04', label: '奇', active: false},
                                        {value: '05', label: '偶', active: false},
                                        {value: '06', label: '清', active: false},
                                    ]
                                },
                            ]
                        },
                        {
                            value: '01',
                            name: '三星直选',
                            label: '直选单式',
                            playType: 1,
                            example: '手动输入号码，至少输入1个三位数号码组成一注。',
                            tips: '每注以英文逗号","分隔，例如：123,234'
                        },
                        {
                            value: '02',
                            name: '三星直选',
                            label: '直选和值',
                            example: '从0-27中任意选择1个或1个以上号码。',
                            playType: 0,
                            positions: [
                                {
                                    order: '1',
                                    title: '和值',
                                    ballList: [
                                        {ball: '0', active: false},
                                        {ball: '1', active: false},
                                        {ball: '2', active: false},
                                        {ball: '3', active: false},
                                        {ball: '4', active: false},
                                        {ball: '5', active: false},
                                        {ball: '6', active: false},
                                        {ball: '7', active: false},
                                        {ball: '8', active: false},
                                        {ball: '9', active: false},
                                        {ball: '10', active: false},
                                        {ball: '11', active: false},
                                        {ball: '12', active: false},
                                        {ball: '13', active: false},
                                        {ball: '14', active: false},
                                        {ball: '15', active: false},
                                        {ball: '16', active: false},
                                        {ball: '17', active: false},
                                        {ball: '18', active: false},
                                        {ball: '19', active: false},
                                        {ball: '20', active: false},
                                        {ball: '21', active: false},
                                        {ball: '22', active: false},
                                        {ball: '23', active: false},
                                        {ball: '24', active: false},
                                        {ball: '25', active: false},
                                        {ball: '26', active: false},
                                        {ball: '27', active: false},
                                    ],
                                    operations: [
                                        {value: '01', label: '全', active: false},
                                        {value: '02', label: '大', active: false},
                                        {value: '03', label: '小', active: false},
                                        {value: '04', label: '奇', active: false},
                                        {value: '05', label: '偶', active: false},
                                        {value: '06', label: '清', active: false},
                                    ]
                                },
                            ]
                        },
                    ]
                },
                {
                    name: '三星组选',
                    typeList: [
                        {
                            value: '10',
                            name: '三星组选',
                            label: '组三复式',
                            example: '从0-9中任意选择2个或2个以上号码。',
                            playType: 0,
                            positions: [
                                {
                                    order: '1',
                                    title: '组三',
                                    ballList: [
                                        {ball: '0', active: false},
                                        {ball: '1', active: false},
                                        {ball: '2', active: false},
                                        {ball: '3', active: false},
                                        {ball: '4', active: false},
                                        {ball: '5', active: false},
                                        {ball: '6', active: false},
                                        {ball: '7', active: false},
                                        {ball: '8', active: false},
                                        {ball: '9', active: false},
                                    ],
                                    operations: [
                                        {value: '01', label: '全', active: false},
                                        {value: '02', label: '大', active: false},
                                        {value: '03', label: '小', active: false},
                                        {value: '04', label: '奇', active: false},
                                        {value: '05', label: '偶', active: false},
                                        {value: '06', label: '清', active: false},
                                    ]
                                },
                                
                            ]
                        },
                        {
                            value: '11',
                            name: '三星组选',
                            label: '组三胆拖',
                            example: '组三是指开奖号码三位任意两位号码相同，如588。至少选2个号码投注，开奖号三位为组三号且包含在投注号码中，即中630元',
                            playType: 0,
                            positions: [
                                {
                                    order: '1',
                                    title: '胆码',
                                    ballList: [
                                        {ball: '0', active: false},
                                        {ball: '1', active: false},
                                        {ball: '2', active: false},
                                        {ball: '3', active: false},
                                        {ball: '4', active: false},
                                        {ball: '5', active: false},
                                        {ball: '6', active: false},
                                        {ball: '7', active: false},
                                        {ball: '8', active: false},
                                        {ball: '9', active: false},
                                    ],
                                },
                                {
                                    order: '2',
                                    title: '拖码',
                                    ballList: [
                                        {ball: '0', active: false},
                                        {ball: '1', active: false},
                                        {ball: '2', active: false},
                                        {ball: '3', active: false},
                                        {ball: '4', active: false},
                                        {ball: '5', active: false},
                                        {ball: '6', active: false},
                                        {ball: '7', active: false},
                                        {ball: '8', active: false},
                                        {ball: '9', active: false},
                                    ],
                                    operations: [
                                        {value: '01', label: '全', active: false},
                                        {value: '02', label: '大', active: false},
                                        {value: '03', label: '小', active: false},
                                        {value: '04', label: '奇', active: false},
                                        {value: '05', label: '偶', active: false},
                                        {value: '06', label: '清', active: false},
                                    ]
                                },
                                
                            ]
                        },
                        {
                            value: '12',
                            name: '三星组选',
                            label: '组三单式',
                            playType: 1,
                            example: '手动输入号码，至少输入1个三位数号码（三个数字中必须有二个数字相同）。',
                            tips: '每注以英文逗号","分隔，例如：122,223',
                        },
                        {
                            value: '13',
                            name: '三星组选',
                            label: '组六复式',
                            example: '从0-9中任意选择3个或3个以上号码。',
                            playType: 0,
                            positions: [
                                {
                                    order: '1',
                                    title: '组六',
                                    ballList: [
                                        {ball: '0', active: false},
                                        {ball: '1', active: false},
                                        {ball: '2', active: false},
                                        {ball: '3', active: false},
                                        {ball: '4', active: false},
                                        {ball: '5', active: false},
                                        {ball: '6', active: false},
                                        {ball: '7', active: false},
                                        {ball: '8', active: false},
                                        {ball: '9', active: false},
                                    ],
                                    operations: [
                                        {value: '01', label: '全', active: false},
                                        {value: '02', label: '大', active: false},
                                        {value: '03', label: '小', active: false},
                                        {value: '04', label: '奇', active: false},
                                        {value: '05', label: '偶', active: false},
                                        {value: '06', label: '清', active: false},
                                    ]
                                },
                                
                            ]
                        },
                        {
                            value: '14',
                            name: '三星组选',
                            label: '组六胆拖',
                            example: '组六是指开奖号码中间三位三个号码各不相同，如135。至少选3个号码投注，开奖号后三位为组六号且包含在投注号码中，即中300元。',
                            playType: 0,
                            positions: [
                                {
                                    order: '1',
                                    title: '胆码',
                                    ballList: [
                                        {ball: '0', active: false},
                                        {ball: '1', active: false},
                                        {ball: '2', active: false},
                                        {ball: '3', active: false},
                                        {ball: '4', active: false},
                                        {ball: '5', active: false},
                                        {ball: '6', active: false},
                                        {ball: '7', active: false},
                                        {ball: '8', active: false},
                                        {ball: '9', active: false},
                                    ],
                                },
                                {
                                    order: '2',
                                    title: '拖码',
                                    ballList: [
                                        {ball: '0', active: false},
                                        {ball: '1', active: false},
                                        {ball: '2', active: false},
                                        {ball: '3', active: false},
                                        {ball: '4', active: false},
                                        {ball: '5', active: false},
                                        {ball: '6', active: false},
                                        {ball: '7', active: false},
                                        {ball: '8', active: false},
                                        {ball: '9', active: false},
                                    ],
                                    operations: [
                                        {value: '01', label: '全', active: false},
                                        {value: '02', label: '大', active: false},
                                        {value: '03', label: '小', active: false},
                                        {value: '04', label: '奇', active: false},
                                        {value: '05', label: '偶', active: false},
                                        {value: '06', label: '清', active: false},
                                    ]
                                },
                                
                            ]
                        },
                        {
                            value: '15',
                            name: '三星组选',
                            label: '组六单式',
                            playType: 1,
                            example: '手动输入号码，至少输入1个三位数号码（三个数字完全不相同）。',
                            tips: '每注以英文逗号","分隔，例如：123,234',
                        },
                        {
                            value: '16',
                            name: '三星组选',
                            label: '组选和值',
                            example: '从1-26中任意选择1个或1个以上号码（若开奖号码为豹子号，则不中奖）。',
                            playType: 0,
                            positions: [
                                {
                                    order: '1',
                                    title: '和值',
                                    ballList: [
                                        {ball: '1', active: false},
                                        {ball: '2', active: false},
                                        {ball: '3', active: false},
                                        {ball: '4', active: false},
                                        {ball: '5', active: false},
                                        {ball: '6', active: false},
                                        {ball: '7', active: false},
                                        {ball: '8', active: false},
                                        {ball: '9', active: false},
                                        {ball: '10', active: false},
                                        {ball: '11', active: false},
                                        {ball: '12', active: false},
                                        {ball: '13', active: false},
                                        {ball: '14', active: false},
                                        {ball: '15', active: false},
                                        {ball: '16', active: false},
                                        {ball: '17', active: false},
                                        {ball: '18', active: false},
                                        {ball: '19', active: false},
                                        {ball: '20', active: false},
                                        {ball: '21', active: false},
                                        {ball: '22', active: false},
                                        {ball: '23', active: false},
                                        {ball: '24', active: false},
                                        {ball: '25', active: false},
                                        {ball: '26', active: false},
                                    ],
                                    operations: [
                                        {value: '01', label: '全', active: false},
                                        {value: '02', label: '大', active: false},
                                        {value: '03', label: '小', active: false},
                                        {value: '04', label: '奇', active: false},
                                        {value: '05', label: '偶', active: false},
                                        {value: '06', label: '清', active: false},
                                    ]
                                },
                            ]
                        },
                    ]
                },
            ]
        },
    ]
}

