<template lang="">
    <div id="app">

        <head-nav v-if="logined"></head-nav>
            <div :class="{'main-content': logined}">
                <router-view></router-view>
            </div>
        <footer-nav v-if="logined"></footer-nav>
    </div>
</template>
<script>

    import headNav from "./components/head-nav.vue";
    import footerNav from "./components/footer-nav.vue";
    import { mapState, mapMutations} from "vuex";
    

    export default {
        name: 'App',
        components: {
            headNav,
            footerNav
        },
        
        computed: {
            ...mapState(['logined',]),
        },
        watch: {
            $route() {
                const session_key = localStorage.getItem('session_key')
                if(this.logined && session_key) {
                    // this.$router.replace('/home')
                } else {
                    this.$router.history.push('/login')
                    this.LOGIN_STATUS(false)
                }
            }
        },
        methods: {
            ...mapMutations(['LOGIN_STATUS', ]),
        },
    }
</script>
<style>
@import './assets/iconfont/iconfont.css';
#app{
    background: url('./assets/images/home-bg.png') no-repeat 0 0;
    background-size: 100% 100%;
}
</style>
