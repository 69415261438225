import axios from 'axios'
import * as signFun from './sign.js'
import { Message, MessageBox } from 'element-ui'  //导入element-ui组件库

const request = axios.create({
    baseURL: '/api',
    // baseURL: '/api/api',
    timeout: 5000
})

request.interceptors.request.use(
    config => {
        config.headers['Content-Type'] = 'application/json; charset=UTF-8'
        config.data = config.data || {}
        config.data.session_key = localStorage.getItem('session_key')
        config.data.timetamp = new Date().getTime()
        // // config.headers['sign'] = "999"
        if (config.url.includes("paygoodsorder")) {
            config.headers['sign'] = signFun.postSign(config.data, 2)
        } else {
            config.headers['sign'] = signFun.postSign(config.data, 1)
        }
        return config
    },
    err => {
        return Promise.reject(err)
    }
)

request.interceptors.response.use(
    res => {
        // console.log('响应前', res)
        if (res.data.code == -1) {
            localStorage.clear()
            location.replace(location.origin + '#/login')
        }
        return res.data
    },
    err => {
        return Promise.reject(err)
    }
)
export default request
